@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

/* common css  */
:root {
  --primary: #ff4e00;
  --secondary: #48281c;
  --primary-light: #fdd7bd;
  --black: #000;
  --black3: #0003;
  --body-bg: #f9fafa;
  --white: #fff;
  --light: #a7b1bb;
  --lightArrow: url('./../../ASSETS/Images/icons/arrow_2.png');
  --darkArrow: url('./../../ASSETS/Images/icons/arrow_1.png');
  --close: url('./../../ASSETS/Images/icons/cancel.png');
  --toggler: url('./../../ASSETS/Images/icons/burger.png');
}

.text-primary {
  color: var(--primary) !important;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--body-bg);
}

.pageTitle h5 {
  font-size: 1.3rem;
}

.btn-primary {
  background: var(--primary);
  border-color: var(--primary);
  box-shadow: 0px 0px 10px 0px var(--black3);
}

.btn-outline-primary {
  border-color: var(--primary);
  color: var(--primary);
}

.btn:hover,
.btn:focus-visible,
.btn:active,
.btn:first-child:active,
.btn-check:checked+.btn,
:not(.btn-check)+.btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  background: var(--secondary);
  border-color: var(--secondary);
  box-shadow: none;
}

.lead {
  font-weight: 400;
}

.modal-medium {
  max-width: 400px;
}

.secondary {
  text-decoration: none;
  color: var(--secondary);
}

.h-100vh {
  height: 100vh;
}

.w-60 {
  width: 60% !important;
}

a {
  text-decoration: none;
}

hr {
  opacity: 0.7;
  margin: 0.8rem 0;
}

.w-60 {
  width: 60%;
}

.profile-sm {
  border-radius: 20px;
  height: 40px;
  width: 40px;
  margin-right: 15px;
  object-fit: cover;
}

.img-thumbnail {
  border: none;
  padding: 0;
  width: 160px;
  min-width: 160px;
  border-radius: 12px;
  height: 160px;
  object-fit: cover;
  display: inline-block;
}

.profile-large {
  max-width: 160px;
  position: relative;
  height: 160px;
  object-fit: cover;
}

.profile-medium {
  width: 100px;
  min-width: 100px;
  height: 100px;
  object-fit: cover;
}

.cursor-pointer {
  cursor: pointer;
}

.mobile-profile p {
  max-width: 240px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.profile-large .edit_profile {
  position: absolute;
  bottom: -7px;
  right: -10px;
  cursor: pointer;
}

.btn-outline-dark {
  border: 1px solid var(--light);
}

/* form css  */
.form-select:not(.select_secondary) {
  background-image: var(--lightArrow);
  background-size: 14px;
  padding-right: 40px !important;
}

.form-select option:hover {
  background: var(--primary);
}

.form-check-input {
  box-shadow: none !important;
}

.form-check-input:checked {
  background: var(--primary);
  border-color: var(--primary);
}

.form-control::placeholder {
  color: var(--light);
}

.form-error {
  border-color: red !important;
}

/* loader css  */
.loader {
  position: fixed;
  top: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0000009c;
}

.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 11px;
  height: 11px;
  border-radius: 20px;
  background: #fff;
  /* border: 1px solid #fff; */
}

.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* Login screen css  */
.login-container {
  background: var(--white);
}

.login-form .mb-3 {
  margin-bottom: 11px !important;
}

.password-field span {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  padding: 15px 10px;
  line-height: 9px;
}

.password-field {
  position: relative;
}

.form-control:focus {
  box-shadow: none;
}

.login-container form {
  max-width: 320px;
  width: 100%;
  margin: 0 auto;
}

.password-field span img {
  max-width: 18px;
  height: 15px;
  object-fit: contain;
  object-position: center;
}

.login-form,
.login-content {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.login-content {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.copyright {
  position: absolute;
  bottom: 15px;
}

.password-field input {
  padding-right: 30px;
}

.login-content h4 {
  font-weight: 400;
  margin-left: auto;
  margin-right: auto;
  max-width: 501px;
}

.email-verification h4 {
  font-size: 1.33em;
}

.email-verification {
  margin-left: auto;
  margin-right: auto;
  max-width: 280px;
}

/* header css  */
span.navbar-toggler-icon {
  background-image: var(--toggler);
  width: 1.4em;
  opacity: 0.8;
}

.header .navbar {
  padding: 5px 0;
  box-shadow: 0px 0px 5px var(--black3);
  background: var(--white);
}

.header-profile .userName {
  min-width: 60px;
  display: inline-block;
  color: var(--black);
  font-weight: 500;
  width: 100%;
}

.userProfile {
  position: relative;
  display: inline-block;
}

.header-profile.nav-item a {
  display: flex;
  align-items: center;
  line-height: 20px;
}

.userPlan {
  font-size: 14px;
}

.header-profile .user-free .profile-sm {
  border: 2px solid var(--white);
}

.header-profile .user-premium .profile-sm {
  border: 2px solid var(--primary);
}

.header-profile .user-premium .userPlan {
  color: var(--primary);
}

.header-profile .user-free .profile-rank {
  display: none;
}

.profile-rank {
  position: absolute;
  width: 19px;
  left: -8px;
  top: 0px;
}

.navbar-toggler {
  color: var(--black);
  opacity: 1;
  border: none;
}

.navbar-toggler:focus,
.form-select:focus {
  box-shadow: none;
}

label.form-label {
  font-size: 0.875em;
  margin-bottom: 0.3rem;
}

.edit_profile {
  max-width: 1000px;
}

.navbar-brand img {
  max-width: 170px;
  height: 42px;
  object-fit: contain;
}

li.header-profile.nav-item {
  /* pointer-events: none; */
  cursor: pointer;
}

/* Side Drawer css  */
.sideDrawer {
  position: fixed;
  left: 0;
  top: 66px;
  background: var(--white);
  min-width: 280px;
  height: calc(100dvh - 67px);
  overflow: auto;
  box-shadow: 2px 1px 6px var(--black3);
  z-index: 1;
}

.mobile-profile .nav-link {
  color: #000;
}

.sideDrawer .nav-item img {
  margin-right: 15px;
  margin-top: -2px;
  width: 22px;
  height: 22px;
  object-fit: contain;
  padding: 1px;
  object-position: center;
}

.sideDrawer .vr-exp.dropdown-toggle.nav-link img {
  padding: 0;
}

.sideDrawer .nav-item .nav-link {
  display: flex;
  align-items: center;
  color: var(--black);
  opacity: 1;
  background: var(--white);
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 12px 45px 12px 27px;
  margin: 4px 1px 4px 0;
  font-weight: 400;
  font-size: 16px;
}

.sideDrawer .nav-item .nav-link:hover {
  background: var(--white);
}

.sideDrawer .nav-item.active .nav-link {
  background: var(--primary-light);
}

.sideDrawer .dropdown-menu {
  border: none;
  padding-left: 54px;
  display: none !important;
}

.sideDrawer .dropdown-menu .dropdown-item {
  padding: 10px;
  color: var(--black);
  font-size: 16px;
  font-weight: 400;
}

.sideDrawer .dropdown-menu .dropdown-item:hover,
.sideDrawer .dropdown-menu .dropdown-item:active,
.sideDrawer .dropdown-menu .dropdown-item:focus {
  background: transparent;
  color: var(--black);
}

.sideDrawer .dropdown-menu .dropdown-item.active {
  background: transparent;
  color: var(--primary);
}

.dropdown-toggle::after {
  border: none;
  background: var(--darkArrow) no-repeat;
  background-size: contain;
  width: 13px;
  height: 12px;
  text-align: center;
  background-position: center;
  margin: 0;
  position: absolute;
  right: 20px;
  transition: all ease 0.3s;
}

.dropdown.menu__active.nav-item .dropdown-toggle::after {
  transform: rotate(90deg);
}

.sideDrawer .menu__active .dropdown-menu {
  display: block !important;
}

.mobile-profile {
  background: #fafafa;
}

/* Breadcrumb css  */
.breadcrumb_icon {
  transform: rotate(180deg);
  max-width: 7px;
  margin-right: 10px;
  margin-bottom: 3px;
}

.breadcrumb-item {
  font-weight: 500;
}

.breadcrumb-item.active {
  color: var(--black);
}

/* modal css  */
.modal-header,
.modal-footer {
  border: none;
}

.modal-title {
  width: 100%;
  padding: 0 25px;
}

.modal-header,
.modal-footer {
  border: none;
}

.modal-footer .btn img {
  max-width: 15px;
  max-height: 15px;
  margin-right: 5px;
  margin-top: -3px;
}

.cancelIcon {
  height: 12px;
}

.btn-close:focus {
  box-shadow: none;
}

.modal-header .btn-close {
  background: var(--close);
  background-size: contain;
  opacity: 1;
  min-width: 28px;
  padding: 0;
  height: 28px;
  position: absolute;
  right: 18px;
  top: 18px;
  opacity: 0.5;
  z-index: 999;
  cursor: pointer;
}

/* main content css  */
.main-content {
  padding-left: 280px;
  margin-top: 67px;
  overflow-x: hidden;
}

.edit_profile .btn {
  min-width: 150px;
}

.change_password {
  max-width: 400px;
}

.change_password .btn {
  min-width: calc(50% - 19px);
}

.uploadBtn input {
  position: absolute;
  opacity: 0;
  right: 0;
  top: 0;
  width: 300px;
  height: 43px;
  cursor: pointer;
}

.uploadBtn {
  position: relative;
  overflow: hidden;
}
.features-modal .modal-content {
  border-radius: 0px;
}
.features li {
  margin-bottom: 5px;
}
.modal-dialog.medium {
  max-width: 650px;
}
.modal-dialog.large {
  max-width: 750px;
}
/* Dashboard css  */
.no-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 160px);
  padding: 15px;
}

.pageTitle {
  padding: 15px 15px 0 15px;
}

.innerHeader {
  padding: 15px 20px 0 20px;
}

.shared_item.dashboard_card,
.Library.dashboard_card,
.Dashboard_Counter {
  margin-left: 15px;
  margin-right: 15px;
}

.Library.dashboard_card .card-body {
  cursor: pointer;
}

.dashboard_card .card-body {
  cursor: pointer;
}

.ReactCrop__child-wrapper {
  max-width: 80vw !important;
  max-height: calc(100vh - 250px) !important;
}

.profile-cont {
  margin-bottom: 15px;
}

.addAssets {
  overflow: hidden;
  position: fixed;
  right: 15px;
  bottom: 15px;
  transition: all ease 0.3s;
  z-index: 97;
}

.customCheck+.card-body {
  position: relative;
  z-index: 8;
}

.addAssets:hover {
  transform: scale(1.05);
}

.addAssets .btn.btn-link {
  background: transparent !important;
  padding: 0 !important;
  border: none !important;
}

.Dashboard_Counter {
  display: flex;
  align-items: center;
  padding: 30px 0px;
  border-radius: 6px;
  box-shadow: 2px 2px 5px #0003;
  max-width: 900px;
}

.Counter_item:not(:first-child) {
  border-left: 2px solid var(--primary);
}

.Counter_item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.dashboard_card .card-body {
  position: relative;
}

.dashboard_card .card {
  overflow: hidden;
  transition: all ease 0.3s;
  box-shadow: 0px 0px 10px #0003;
  border-color: #0000001f;
}

.dashboard_card .card-footer {
  transition: all ease 0.3s;
  text-transform: capitalize;
  border-top: none;
}

.assets_image {
  width: 100%;
  height: 170px;
  object-fit: cover;
}

.assets_item .card.selected .assets_image {
  height: calc(170px - 16px);
}

.dashboard_card .card-footer img[alt="Folder"] {
  margin-top: -3px;
}

.dashboard_card .card:hover {
  border-color: var(--primary);
}

.dashboard_card .card:hover .card-footer {
  background: var(--primary);
  color: #fff;
}

.dashboard_card .card:hover .card-footer img {
  filter: brightness(100);
}

.shared_item .assets_image {
  height: 150px;
}

.shared_item .col-sm-6 {
  width: 168px;
  padding-left: 8px;
  padding-right: 8px;
}

.orangeHeader {
  background: #fdd7bd;
}

.sharedFF span.customCheck {
  display: none;
}

.profile-sm-square {
  width: 50px;
  height: 50px;
  border: 1px solid #e1bba2;
  border-radius: 3px;
  object-fit: cover;
}

.shared_item .row {
  margin-left: -8px;
  margin-right: -8px;
}

.shared_item .card-footer {
  padding: 13px;
  display: flex;
  align-items: center;
}

.shared_item .slick-slider {
  padding: 0;
}

.dashboard_card .card:hover .card-footer .light_icon {
  display: inline-block;
}

.dashboard_card .card .card-footer .light_icon {
  display: none;
}

.dashboard_card .card:hover .card-footer .dark_icon {
  display: none;
}


.drop-modal .modal-content button.modalClose {
  position: absolute;
  padding: 0 !important;
  background: transparent;
  border: none;
  top: -17px;
  right: -20px;
}

.drop-modal .modal-content button.modalClose:active {
  background: transparent;
}

.small-thumb,
img.dzu-previewImage {
  width: 60px;
  height: 45px;
  object-fit: cover;
  border-radius: 4px;
}

.uploaded-items,
.dzu-previewContainer {
  border: 1px solid #ccc !important;
  border-radius: 6px;
  margin-top: 16px;
  padding: 10px !important;
}

/* .Drop_Area {
  min-height: 150px;
  text-align: center;
  line-height: 150px;
  border: 1px dashed #a5a5a5;
  border-radius: 6px;
} */

.drop-modal .modal-content button img {
  max-width: 23px;
}

button.play {
  display: block;
  width: 14px;
  height: 14px;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 10px solid #2c3e50;
  margin: 16px auto 0px auto;
  position: relative;
  z-index: 1;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  border-right: none;
  background: transparent;
  float: right;
}

.progress-bar {
  background: var(--primary) !important;
}

button.play.finished {
  border: none;
  transform: rotate(45deg);
  margin-top: 22px;
}

.play.finished:after {
  content: "";
  width: 14px;
  height: 2px;
  position: absolute;
  left: -9px;
  top: 6px;
  background: #000;
}

.play.finished:before {
  content: "";
  width: 2px;
  height: 14px;
  position: absolute;
  left: -3px;
  top: 0px;
  background: #000;
}

.play.active {
  border-color: transparent;
}

.play.active:after {
  content: "";
  opacity: 1;
  width: 9px;
  height: 14px;
  background: #2c3e50;
  position: absolute;
  left: -10px;
  top: -6px;
  border-left: 3px solid #2c3e50;
  box-shadow: inset 3px 0 0 0 #f9f9f9;
}

.upload_item .progress {
  width: calc(100% - 40px);
  float: left;
  margin-top: 21px;
}

body:not(.modal-open) {
  overflow: auto !important;
}

body.modal-open {
  overflow: hidden !important;
}

.userName {
  text-transform: capitalize;
}

.userInfo h5 {
  text-transform: capitalize;
}

.dzu-dropzone {
  width: 100% !important;
  border: 1px dashed #a5a5a5 !important;
  border-radius: 6px;
}

.dzu-inputLabel {
  color: #000 !important;
}

.dzu-inputLabel span {
  color: #0d6efd;
}

progress {
  background: var(--primary);
  height: 5px;
  border-radius: 6px !important;
  overflow: hidden;
}

progress {
  color: #e9ecef;
  margin-right: 7px;
}

progress::-moz-progress-bar {
  background: #e9ecef;
}

.dzu-previewStatusContainer {
  margin-right: 5px;
}

progress::-webkit-progress-value {
  background: var(--primary);
}

progress::-webkit-progress-bar {
  background: #e9ecef;
}

span.dzu-previewFileName {
  word-break: break-word;
  padding-right: 10px;
}

/* assets management css  */
.assets_item .card {
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.assets_item .card:hover {
  border-color: var(--primary);
}

/* .assets_item .card:after {
  content: "";
  width: 16px;
  height: 16px;
  position: absolute;
  right: 4px;
  top: 4px;
  z-index: 9;
  border-radius: 10px;
  border: 1px solid #fff;
} */
.customCheck {
  position: absolute;
  right: 5px;
  top: 5px;
  background: transparent;
  z-index: 9;
  width: 18px;
  height: 18px;
  border-radius: 20px;
  border: 1px solid #fff;
  cursor: pointer;
}

.assets_item .card.selected .customCheck {
  background: var(--primary);
  border-color: var(--primary);
  right: -4px;
  top: -4px;
}

.assets_item .card.selected span:before {
  content: "";
  width: 10px;
  height: 6px;
  position: absolute;
  top: 4px;
  right: 3px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  z-index: 10;
  transform: rotate(-40deg);
}

.assets_item .card.selected {
  border: 8px solid #fdd7bd;
  overflow: initial;
}

.innerHeader.withBorder {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e3e3e3;
  margin-bottom: 10px;
}

.btn-sm img {
  max-width: 13px;
  margin-top: -2px;
}

nav.mb-0[aria-label="breadcrumb"] ol.breadcrumb {
  margin-bottom: 0;
}

.btn img {
  margin-top: -2px;
}

.btn.btn-link:not(.btn-sm),
.btn.btn-link:focus,
.btn.btn-link:hover {
  padding: 0 !important;
  border: none !important;
  text-decoration: none;
  color: #000;
  background: transparent;
}

.assets_item .card .card-body {
  display: flex;
  background: #eee;
}

.modal-dialog.drop-modal.imageModal img {
  max-height: 80vh;
  object-fit: contain;
}

.modal-dialog.drop-modal.imageModal figure {
  display: flex;
  align-items: center;
}

.imageModal li[role="button"] img {
  display: none;
}

.imageModal li[role="button"] {
  width: 10px;
  height: 10px;
  border-radius: 20px;
  border: 1px solid #fff;
  min-width: 10px;
  margin: 3px;
  opacity: 1;
}


.imageModal li[role="button"]._19gvi {
  background: #fff;
}

.imageModal span.__JnHV {
  display: none;
}

.imageModal ._3lwW_._lhmht {
  display: none;
}

.imageModal ul._-LJ2W {
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  flex-wrap: wrap;
}

.imageModal span.__JnHV {
  display: none;
}

.imageModal ._3lwW_._lhmht {
  display: none;
}

.imageModal ._lfOsC._1Pekn svg._3WRGR {
  opacity: 0;
}

.imageModal ._3lwW_._1cqA3 ._lfOsC._1Pekn:after {
  transform: rotate(135deg);
  right: 7px;
}

.imageModal ._ZTBlf {
  color: #fff;
  opacity: 1;
}

.imageModal ._ZTBlf {
  opacity: 1;
}

.imageModal ._3lwW_._1oKnM {
  bottom: 5px;
  top: auto;
  right: 5px;
}

.imageModal ._2c50p {
  position: absolute;
  bottom: 0;
  width: calc(100% - 40px);
}

.imageModal ._2qwzr {
  position: static;
}

.imageModal ._3WRGR {
  width: 24px;
}

.imageModal ._lfOsC._1Pekn:after {
  content: "";
  width: 20px;
  height: 20px;
  border-right: 4px solid;
  border-bottom: 4px solid;
  transform: rotate(-45deg);
  right: 15px;
  position: relative;
}

.assets_item.am_audios .card img {
  height: 60px;
  margin: 3.2em auto;
}

.assets_item.am_audios .card.selected img {
  margin: 2.7em auto;
}

.DashboardNoItems {
  text-align: center;
  background: #eee;
  padding: 5rem 15px;
  margin-top: 15px;
}

.share_people .form-control,
.share_people .form-select {
  background-color: #ececec;
  border-color: #ececec;
}


.share_people .btn.btn-primary {
  min-width: 110px;
}

/* .assets_item .card.selected img {
  height: calc(168px - 10px);
} */
.videoModal .modal-body,
.imageModal .modal-body {
  padding: 0;
  position: relative;
  overflow: hidden;
}


.imageModal .modal-body>div {
  width: 100% !important;
  /* height: auto !important; */
  display: flex;
  border-radius: 7px;
  overflow: hidden;
}

.videoModal .modal-body>div {
  width: 100% !important;
  height: auto !important;
  display: flex;
  border-radius: 7px;
  overflow: hidden;
}

.modal-dialog.videoModal,
.modal-dialog.imageModal {
  max-width: 800px;
  width: auto;
}

.videoCard .card-body>div {
  height: 170px !important;
  width: 100% !important;
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: center;
}

.videoCard .card.selected .card-body>div {
  height: 158px !important;
}

.videoCard .assets_item .card .card-body {
  align-items: center;
  justify-content: center;
  /* background: #000; */
}

.audio-modal .modal-body {
  padding-left: 0;
  padding-right: 0;
}

.rhap_container {
  box-shadow: none !important;
}

.audio-modal .modal-body {
  padding-left: 0;
  padding-right: 0;
}

.rhap_progress-indicator {
  background: var(--primary) !important;
  height: 15px !important;
  width: 15px !important;
  top: -4px !important;
}

.rhap_volume-indicator {
  background: var(--primary) !important;
  top: -3px !important;
}

.rhap_volume-bar {
  height: 6px;
}

.rhap_progress-filled {
  background: var(--primary) !important;
  border-radius: 6px !important;
}

.rhap_progress-section {
  padding: 10px 0 20px !important;
}

.rhap_progress-bar {
  height: 8px !important;
  border-radius: 6px !important;
}

img.play_image {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.shared_item .slick-track {
  margin-left: 0;
}

.imageModal li[role=button] {
  display: none;
}

.orangeHeader img {
  cursor: pointer;
}

.card-footer {
  padding: 13px;
}

.userCard img {
  max-height: 177px;
  object-fit: cover;
}

.userCard,
.VR_rooms,
.pin-management,
.inviteCont {
  margin: 0 15px;
}

.VR_rooms .card {
  border: none;
  background: transparent;
  cursor: pointer;
}

.VR_rooms .card-footer {
  border: none !important;
  background: transparent;
  padding-left: 0px;
  padding-right: 0px;
}

.VR_rooms .card-body {
  box-shadow: 0px 0px 10px #0003;
  border-radius: 6px;
  background: #fff;
  padding: 45px 15px;
  position: relative;
}

img.vr-icon {
  position: absolute;
  right: 8px;
  top: 8px;
}

button.btn.btn-white:hover {
  color: #fff;
}

.nav-tabs a {
  background: #e8e8e8 !important;
  border-radius: 0 !important;
  margin-right: 7px;
  margin-bottom: 7px !important;
  min-width: 150px;
  text-align: center;
  color: #000;
  border-bottom: 5px solid #e8e8e8 !important;
  border-top: 5px solid #e8e8e8 !important;
  cursor: pointer;
}

.nav-tabs a.active {
  border-bottom-color: var(--primary) !important;
  color: #000 !important;
}

.tab-content {
  background: #e8e8e8;
  padding: 15px;
  padding-bottom: 0;
}

.nav-tabs {
  border: none;
}

.nav-tabs .nav-link:hover {
  color: #000 !important;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.userCard .card-body {
  background: #d4d4d4;
}

.modal-dialog .tab-content .tab-pane {
  max-height: calc(100vh - 290px);
  overflow-x: auto;
  min-height: 170px;
  width: 100%;
  padding: 0 15px;
}

.modal-xl .modal-body {
  padding: 25px;
}

.imageHeader {
  position: relative;
}

.imageHeader h3 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 2rem;
  width: 100%;
  text-align: center;
}

.asset_filter {
  float: right;
}

.form-select.select_secondary {
  background-color: #e8e8e8;
  border: none;
  border-radius: 3px;
  padding-right: 40px !important;
}

.asset_filter select {
  width: auto;
}

.modal .no-content img {
  max-width: 250px;
}

.modal .no-content {
  min-height: auto;
}

.Toastify__progress-bar.Toastify__progress-bar--animated.Toastify__progress-bar-theme--light.Toastify__progress-bar--error {
  background: var(--primary);
}

.Toastify__progress-bar.Toastify__progress-bar--animated.Toastify__progress-bar-theme--light.Toastify__progress-bar--default {
  background: var(--primary);
}

.pm-card {
  padding: 5px;
  border: none;
  box-shadow: 0px 0px 10px -3px #0005;
  border-radius: 10px;
}

.viewPin {
  margin-left: 26px;
  max-width: 18px;
  filter: brightness(0.5);
  margin-top: -3px;
  cursor: pointer;
}

.pin.hide {
  font-size: 50px;
  line-height: 0px;
  display: inline-block;
  letter-spacing: -4.8px;
  height: 19px;
  position: relative;
  top: -5px;
}

.modal-pinGeneration h5 {
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.modal-pinGeneration button {
  width: calc(50% - 20px);
  max-width: 150px;
}

.truncateEmail {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.assets_item .videoCard .card .card-body {
  align-items: center;
  justify-content: center;
  background: #2e2e2e;
}

.videoModal .modal-body video {
  max-height: calc(100vh - 60px);
}

.padlock {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #0005;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease 0.3s;
  transform: scale(0);
  border-radius: 6px;
}

.VR_rooms .card-body .padlock img {
  width: 27px;
}

.VR_rooms .card:hover .card-body .padlock {
  transform: scale(1);
}

.inner_lists {
  max-height: calc(100vh - 164px);
  overflow-y: auto;
}

.invite-screen {
  background: #fff;
  border-radius: 11px;
  padding: 25px 30px;
  max-width: 700px;
  margin: 0 auto;
  box-shadow: 2px 2px 10px -3px #0004;
}

.invite-screen label {
  margin-bottom: 5px;
  font-size: 15px;
}

.invite-screen h6 {
  line-height: 24px;
}

.invite-screen label {
  margin-bottom: 5px;
  font-size: 15px;
}

.ql-toolbar.ql-snow {
  display: none;
}

.invite-screen .ql-container.ql-snow {
  border: 1px solid #ced4da !important;
  border-radius: 6px;
  min-height: 150px;
}

.assets_item .card-footer {
  background: #fff;
  border-top: none;
}

.assets_item .card-footer p {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.bg-primary {
  background: var(--primary) !important;
}

/* styline  */
.align-end {
  text-align: end;
}

.align-end img.margin {
  padding-right: 10px;
  margin-left: 10px;
}

.virtual-tour-and-img .card-body img {
  height: 220px;
  max-height: 300px;
}

.cancel-input-boxes-container {
  padding: 0;
  margin-top: 7px;
}

.cancel-input-boxes-container img {
  /* height: 24px; */
  cursor: pointer;
}

.add-input-boxes-container {
  padding-bottom: 10px;
}

.add-input-boxes-container_lable {
  padding-bottom: 10px;
}

.invite-contributors-add-assets {
  margin-right: 20px !important;
}

.add-input-boxes-container-upper-div {
  background: #fff;
  border-radius: 11px;
  padding: 25px 30px;
  max-width: 700px;
  margin: 0 auto;
  box-shadow: 2px 2px 10px -3px #0004;
}

.add-new-contributor-button {
  border: 0px;
  background: white;
}

.cursor-none {
  cursor: not-allowed;
  pointer-events: all !important;
}

.quill {
  background-color: #ececec;
}

.second-label-input-and-email {
  position: relative;
  left: 208px;
}

.modal-wrapper-thankyou {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  width: 100%;
  margin: 50px;
}

.video-optimized-info-text {
  font-size: 19px;
}

.video-optimized-info-text-footer {
  position: relative;
  top: 17px;
}

.files-upload-processing {
  height: 100px;
}


.create-virtul-tour-nav a {
  border-top: 0px !important;
  border-bottom: 0px !important;
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
  /* width: 350px; */
  margin-bottom: 0px !important;
  /* height: 55px;
    padding-top: 16px; */
}

.create-virtul-tour-nav a.active {
  border-bottom-color: var(--primary) !important;
  color: #fff !important;
  background: var(--primary) !important;
}

/* .create-virtul-tour-nav-itme {
  border-bottom-color: var(--primary) !important;
  border-bottom: 5px solid;
} */


.virtual-tour-radio-button {
  width: 18px;
  height: 18px;
  accent-color: #ff4e00;
}

.create-virtul-tour-nav .active.nav-link:hover {
  color: #fff !important;
}

.create-virtul-tour-nav a.nav-link {
  font-size: 14px;
  padding: 0.5rem 0.4rem;
}

.create-virtul-tour-nav li:last-child a.nav-link {
  margin-right: 0;
}

.virtualMdl .tab-content {
  padding: 15px 0px;
}

.virtualMdl .tab-content .tab-pane {
  padding: 0px;
  min-height: 116px;
}

.virtualMdl .modal-body {
  padding: 25px;
}

.virtualMdl .form-control {
  background: #fafafa;
}

.x-hidden {
  overflow-x: hidden;
}

.tour_image {
  width: 100%;
  height: 60px;
  border-radius: 4px;
  object-fit: cover;
  box-shadow: 0px 3px 10px 0 #0003;
  max-width: 180px;
}

.virtualMdl p {
  font-size: 11px;
  margin-top: 4px;
  margin-bottom: 0;
}

.virtualMdl label {
  font-size: 14px;
}


/* panorama desing start */


.panorama {
  width: 1200px;
  height: 700px;
}

.custom-hotspot {
  background-image: url('./../Images/icons/hotspotlocation.png');
  width: 45px;
  height: 45px;
  background-size: contain;
  background-repeat: no-repeat;
}

.hotspot-location {
  background-image: url('./../Images/icons/hotspotlocation.png');
  width: 45px;
  height: 45px;
  background-size: contain;
  background-repeat: no-repeat;
}

.hotspot-image {
  background-image: url('./../Images/icons/hotspotImage.svg');
  width: 45px;
  height: 45px;
  background-size: contain;
  background-repeat: no-repeat;
}

.hotspot-video {
  background-image: url('./../Images/icons/hotspotVideo.png');
  width: 45px;
  height: 45px;
  background-size: contain;
  background-repeat: no-repeat;
}

.hotspot-audio {
  background-image: url('./../Images/icons/hotspotAudio.png');
  width: 45px;
  height: 45px;
  background-size: contain;
  background-repeat: no-repeat;
}

.hotspot-url {
  background-image: url('./../Images/icons/hotspotURL.png');
  width: 45px;
  height: 45px;
  background-size: contain;
  background-repeat: no-repeat;
}

div.custom-tooltip span {
  visibility: hidden;
  position: absolute;
  border-radius: 3px;
  background-color: #fff;
  color: #000;
  text-align: center;
  max-width: 200px;
  padding: 5px 10px;
  margin-left: -220px;
  cursor: default;
}

div.custom-tooltip:hover span {
  visibility: visible;
}

div.custom-tooltip:hover span:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-width: 10px;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
  bottom: -20px;
  left: -10px;
  margin: 0 50%;
}


/* showing hot-spot */

.showHotspot {
  visibility: hidden;
  width: 250px;
  height: 182px;
  background: red;
  text-align: center;
  position: relative;
  left: 27px;
  top: 26px;
}


div.custom-tooltip:hover div {
  visibility: visible;
  position: relative;
  left: 10px;
  top: 10px;
}

/* adding hot-spot */
.myDiv {
  width: 350px;
  background: #393a39;
  text-align: center;
  position: relative;
  left: 55px;
  top: 0px;
  padding: 15px 25px 25px;
  font-size: 12px;
  border-radius: 6px;
}

.myDiv:after {
  content: "";
  width: 15px;
  height: 15px;
  background: #393a39;
  position: absolute;
  top: 13px;
  left: -6px;
  transform: rotate(45deg);
}

.myDiv label {
  width: 100%;
  text-align: left;
  color: #fff;
  font-size: 12px;
  margin-bottom: 3px;
}

.pnlm-container .myDiv * {
  box-sizing: border-box;
}
/* To hide background cubes*/
.pnlm-load-box > div, .pnlm-load-box p {
    visibility: hidden !important;
}
.pnlm-load-box {
    width: 100%;
    height: 100%;
    left: 0;
    margin: 0;
    top: 0;
}
.pnlm-load-box .loader {
    visibility: visible !important;
}
.pnlm-container {
  background: #f9fafa !important;
}

.myDiv input {
  background: #fff2;
  border: transparent;
  padding: 0.5rem 1rem;
  font-size: 12px;
  color: #FFF;
}

.myDiv input:focus {
  background: #fff2;
  color: #FFF;
}


.rdo-btn span {
  position: relative;
  cursor: pointer;
}

.rdo-btn input {
  opacity: 0;
}

.rdo-btn {
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.myDiv .form-group {
  margin-bottom: 15px;
}

.rdo-btn span label:after {
  content: "";
  border: 2px solid #fff;
  width: 16px;
  height: 16px;
  position: absolute;
  left: 0;
  border-radius: 9px;
  top: 1px;
}

.btn.pannelium-tag-button:not(.btn-sm) {
  min-width: 85px;
  padding: 0.3rem 1.2rem !important;
  margin-top: 10px;
  font-size: 13px;
}

.rdo-btn span input:checked+label:after {
  border: 2px solid #ff4e00;
}

.rdo-btn span input:checked+label:before {
  content: "";
    width: 10px;
    height: 10px;
    background: #ff4e00;
    position: absolute;
    left: 3px;
    top: 4px;
    border-radius: 10px;
}

.rdo-btn label {
  width: auto;
  padding-left: 5px;
  cursor: pointer;
}

.browse-option input {
  width: 450px;
  position: relative;
  z-index: 2;
  opacity: 0;
  cursor: pointer;
  left: -100px;
  height: 34px;
}

.browse-option {
  background: #fff2;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}

.browse-option span {
  position: absolute;
  left: 0;
  padding: 0.5rem 1rem;
  color: #fff;
  text-align: center;
  width: 100%;
  top: 0;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 21px;
}

.panorama-wrap {
  position: relative;
  width: 100%;
  transition: all ease 0.3s;
  z-index: 3;
}

.virtual-tour-wrap {
  overflow: hidden;
  position: relative;
}
.tour-drawer-inner {
    width: 280px;
    height: 100%;
    background: #fff;
    transition: all ease 0.3s;
    padding: 5px 0;
    max-height: calc(100vh - 67px);
    min-height: 600px;
    overflow: auto;
}

.virtual-tour-drawer.actDrawer {
    right: 0;
}
.virtual-tour-drawer.actDrawer {
      right: 0;
}

.tagitem {
  display: flex;
  align-items: center;
  padding: 7px 20px;
  width: 100%;
  cursor: pointer;
}

.tagitem img {
  width: 17px;
  height: 17px;
  object-fit: contain;
}

.tagitem p {
    width: calc(100% - 46px);
    margin: 0px;
    line-height: 17px;
    padding-right: 5px;
    font-size: 15px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.tagitem img.tagImage {
  text-align: left;
  margin-right: 12px;
}

.virtual-tour-drawer .accordion-button {
  font-weight: 500;
  font-size: 16px;
  padding-bottom: 7px;
}

.aordion-button:focus {
  box-shadow: none !important;
}

.virtual-tour-drawer .accordion-button:after {
  width: 14px;
  height: 14px;
  background-size: contain;
  background-image: var(--bs-accordion-btn-icon);
  /* margin-right: 10px; */
}

.virtual-tour-drawer .accordion-item {
  border: none;
}

/* panorama desing end */
.addtag img.add {
  transform: rotate(45deg);
  margin-right: 4px;
  max-width: 10px;
}

.addtag img.cancel {
  max-width: 14px;
  margin-right: 5px;
}

.collapseBtn {
    position: absolute;
    top: 20px;
    left: -40px;
    z-index: 2;
}

.CollapseBtn {
  position: absolute;
  top: 20px;
  right: 0;
  z-index: 2;
}

.backBtn .btn.btn-round {
  background: #0008;
  width: 40px;
  height: 40px;
  text-align: center;
  padding: 0 !important;
  border-radius: 100%;
}

.virtual-tour-drawer {
position: absolute;
    top: 0;
    right: -280px;
    height: 100%;
    transition: all ease 0.3s;
    z-index: 9;
}

.tagitem:hover,
.tagitem.active {
  background: #ff4e00;
  color: #fff;
}

.tagitem:hover img,
.tagitem.active img {
  filter: brightness(0) invert(1);
}

.backBtn {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 2;
}

.virtual-tour-drawer .accordion-body {
  padding: 0;
}

.accordion-button:not(.collapsed),
.accordion-button:hover,
.accordion-button:active,
.accordion-button:focus {
  background: transparent;
  border: none !important;
  box-shadow: none;
  color: #000;
}

.no-cnt {
  font-style: italic;
  color: #bbb;
  font-size: 15px;
  margin: 0;
  padding: 0 20px;
}
.myDiv .no-cnt {
    height: 134px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-container,
.video-container,
.audio-container {
  width: 100%;
  background: #161616;
  padding: 7px 10px;
  padding-right: 5px;
  border-radius: 6px;
}

.image-container>div,
.video-container>div,
.audio-container>div {
  width: 100%;
  height: 145px;
  padding-right: 7px;
  overflow-y: auto;
}

.image-container .assets_image,
.video-container .assets_image,
.audio-container .assets_image {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  position: relative;
  z-index: 8;
}

.image-container span,
.video-container span,
.audio-container span {
  border-radius: 6px;
  overflow: hidden;
  margin: 4px;
  width: calc(33.33% - 8px);
  float: left;
  height: 64px;
  cursor: pointer;
  position: relative;
}

span.class_second img {
  border: 3px solid #ffae8a;
}

span.class_second:after {
  content: "";
  width: 7px;
  height: 4px;
  position: absolute;
  top: 5px;
  right: 5px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  z-index: 10;
  transform: rotate(-40deg);
}

.image-container span:before,
.video-container span:before,
.audio-container span:before {
  content: "";
  border: 1px solid #ccc;
  width: 12px;
  height: 12px;
  border-radius: 20px;
  position: absolute;
  top: 2px;
  right: 2px;
  z-index: 9;
  background: #0003;
}

span.class_second:before {
  background: #ff4e00;
  border-color: #ff4e00;
}

div#audio-result-container span {
  height: auto;
}

div#audio-result-container span img {
  height: 53px;
}

div#audio-result-container span label {
  margin-bottom: 0;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
  font-size: 11px;
}

div#video-result-container span label.vid_icon {
  background-image: url('./../Images/icons/play.svg');
  width: 20px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  transform: translate(-50%, -50%);
}
.addtag >div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.addtag button, .addtag span.tagButtonInfo {
    bottom: 25px;
    left: 0;
    position: absolute;
    text-align: center;
    transition: all .3s ease;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
}
 .addtag span.tagButtonInfo {
    background: #0009;
    color: #fff;
    padding: 15.5px 20px;
    border-radius: 6px;
    font-size: 15.5px;
    font-weight: 300;
    text-align: center;
    bottom: 90px;
    min-width: 387px;
}
.myDiv .btn.btn-secondary {
    background: #242424;
}
.myDiv .btn {
    margin: 0 5px;
}
.pnlm-render-container .custom-tooltip span p {
    margin: 0;
}

.pnlm-render-container .custom-tooltip span {
    background: #0009;
    color: #fff;
        font-size: 13px;
    padding: 10px 15px;
}

.pnlm-render-container .custom-tooltip:hover span:after {
    border-color: #0009 transparent transparent transparent;
}

/*
 *  scroll-style
 */

.scroll-style::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 5px;
  background-color: #eee3;
}

.scroll-style::-webkit-scrollbar
{
  width: 7px;
  background-color: #0003;
}

.scroll-style::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #ff4e00;
}
.collapseBtn img {
    cursor: pointer;
}
.pnlm-hotspot-base {
    z-index: 2;
}

.prevent-context-view {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}
.subscription-cards .plan .pm-card,.subscription-cards .plan .card-body {
  padding: 0;
}
.subscription-cards .sub-card > div.card-body {
  border-radius: 10px;
}
.subscription-cards .card-header {
  min-height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid #dcd9d9;
}
.plan .sub-card .card-body {
  padding: 25px 0 ;
}
.main-content> .bg-light {
  background: #f2f2f2 !important;
  min-height: calc(100vh - 67px);
}
.pay input:focus {
  border-color: var(--primary) !important;
  box-shadow:none !important;
}
.subscription-cards .pm-card {
  margin: 30px 0;
  position: relative;
  z-index: 2;
}
.subscription-cards .sub-card.premium .card-header {
  border-color: var(--primary);
}
.sub-card.premium .card-header{
  color:var(--primary);
}

.subscription-cards >div.plan {
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
}

.sub-card .list-group-item {
  border: none;
  position: relative;
  padding: 14px 10px 15px;
}

.sub-card .list-group-item:not(:last-child):after {
  content: "";
  background: #ccc;
  position: absolute;
  bottom: 0px;
  width: 70px;
  left: 50%;
  transform: translateX(-50%);
  height: 2px;
}
.subscription-cards >div.plan:before {
  content: "";
  width: calc(100% - 60px);
  background: #007bd9;
  height: 100%;
  position: absolute;
  top: 0;
  border-radius: 13px;
  left: 50%;
  z-index: 1;
  transform: translateX(-50%);
}
.subscription-cards >div.plan.premium:before {
  background: var(--primary);
} 

.sub-card.premium .list-group-item:after {
  background: var(--primary);
}
.order-detail {
  height: 100%;
  background: #fff;
  min-height: calc(100dvh - 67px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  box-shadow: 0px 0px 7px #0001;
}
.order-detail td {
  padding: 3px 0;
}
.order-detail .img-thumbnail {
  width: 100px;
  min-width: 100px;
  height: 100px;
  object-fit: cover;
  border: 2px solid #ccc;
  padding: 10px;
  box-shadow: 0px 2px 7px #0003;
}
.x-small {
  font-size: 0.75rem;
}
.link-text {
  color: #029cff !important;
}
.more-icon img {
  padding: 10px;
  max-height: 37px;
  object-fit: contain;
}
.long-btn {
  min-width: 120px;
}
ul.features li::marker {
  color:var(--primary);
}
.sub-vertically-center {
  min-height: calc(100dvh - 130px);
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.add-input-boxes-container_lable {
  font-size: 15px;
}
.mw-500 {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}
.subscription-cards .list-group li,.default-card  {
  border: none;
  position: relative;
  padding-left: 50px;
  text-align: left;
}
.default-card {
  white-space: nowrap;
}

.default-card:before {
  content: "";
  position: absolute;
  left: 20px;
  width: 17px;
  top: 3px;
  background: var(--primary);
  height: 17px;
  border-radius: 20px;
}

.default-card:after {
  content: "";
  width: 8px;
  height: 5px;
  border-left: 2px solid#fff;
  border-bottom: 2px solid #fff;
  position: absolute;
  top: 8px;
  left: 24.7px;
  transform: rotate(-43deg);
}

.subscription-status{
  background-color: #EB9A00;
  margin-left: 12px;
  padding: 6px 12px 6px 12px;
  border-radius: 12px;
  color: white;
  white-space: nowrap;
}

/* responsive css  */
@media only screen and (min-width:1601px) {
  .pin-management .col-xl-3 {
    max-width: 20%;
  }
}

@media only screen and (min-width:1400px) {
  .assets_item .col-lg-3 {
    flex: 0 0 auto;
    width: 20%;
  }


  .assets_image {
    height: 180px;
  }

  .assets_item .card.selected .assets_image {
    height: calc(180px - 16px);
  }

  .modal-xl .modal-body {
    padding: 35px;
  }

  .VR_rooms .col-xl-3,
  .userCard .col-lg-3 {
    max-width: 300px;
  }

  .VR_rooms .card-body {
    padding: 50px 15px;
  }
}

@media only screen and (min-width: 991px) {
.virtual-tour-wrap.act .addtag button, .virtual-tour-wrap.act .addtag span.tagButtonInfo {
    left: calc(50% - 140px);
}
.mh-100-67{
  min-height: calc(100vh - 99px);
}
}
@media only screen and (min-width: 768px) {
  .login-form .form-control {
    background: var(--body-bg);
  }
 
  .btn.btn-sm {
    padding: 0.5rem 1.2rem !important;
  }
 
  .imageHeader {
    padding: 0 0.5rem;
  }

  button.btn.btn-long {
    min-width: 120px;
  }

  .orangeHeader h4 {
    padding-left: 10px;
  }

  .slick-slide {
    width: 168px !important;
  }

  .modal-footer .btn.btn-primary,
  .modal-footer .btn.btn-outline-secondary {
    min-width: 160px;
    margin-bottom: 15px;
  }

  .modal-medium .modal-footer .btn.btn-primary,
  .modal-medium .modal-footer .btn.btn-outline-secondary {
    min-width: 120px;
    margin-bottom: 15px;
  }

  .btn:not(.btn-sm) {
    padding: 0.575rem 1.4rem !important;
  }

  .form-control,
  .form-select {
    padding: 0.575rem 1.05rem;
  }
}

@media only screen and (min-width: 577px) {
  li.create-virtul-tour-nav-itme.nav-item {
    width: 50%;
  }
  .sub-card.free ul {
    padding-bottom: 44px;
  }
  .create-virtul-tour-nav {
    border-bottom: 2px solid #ff4e00;
  }
}

@media only screen and (max-width: 991px) {
  .Counter_item {
    width: 50% !important;
    min-height: 65px;
  }
  .order-detail {
    min-height: auto;
  }
  .backBtn {
    top: 10px;
    left: 10px;
  }

  .invite-screen {
    padding: 20px;
  }

  .modal-dialog.drop-modal {
    max-width: calc(100% - 40px);
  }

  .modal-dialog.modal-xl {
    max-width: calc(100% - 15px);
  }


  .imageHeader h3 {
    font-size: 1.5rem;
  }

  .Dashboard_Counter {
    flex-wrap: wrap;
  }

  .Counter_item:nth-child(2),
  .Counter_item:nth-child(1) {
    margin-bottom: 50px;
  }

  .Counter_item:nth-child(3) {
    border-left: none;
  }

  .modal-dialog.drop-modal.modal-md {
    max-width: 600px;
  }
}

@media only screen and (max-width: 767px) {
  .userName {
    font-size: 24px;
  }

  .drawer-wrap hr {
    margin-bottom: 0;
  }

  .no-content img {
    max-width: 250px;
  }

  .assets_item .card video {
    height: auto !important;
  }

  .modal-dialog .tab-content .tab-pane {
    max-height: calc(100vh - 350px);
  }

  .asset_filter {
    width: 100%;
    float: none;
    margin: 10px 0 !important;
  }

  ul.nav.nav-tabs {
    flex-wrap: nowrap;
    overflow: auto;
  }

  .shared_item .slick-list {
    padding: 0 !important;
  }

  .shared_item .slick-slide {
    max-width: 168px;
  }

  .orangeHeader h4 {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  }

  .login-form {
    background: var(--body-bg);
  }

  .password-field span {
    padding: 11px;
  }

  a.navbar-brand {
    width: calc(100% - 60px);
    text-align: center;
    margin-right: 0;
  }

  .sideDrawer {
    transform: translateX(-300px);
    transition: all ease 0.3s;
    height: calc(100dvh - 60px);
    top: 61px;
  }
 
  /* .drawer-wrap {
    max-height: calc(100vh - 160px);
    overflow-y: auto;
  } */

  .main-content {
    padding-left: 0px;
    margin-top: 62px;
  }
.panorama.pnlm-container {
    height: calc(100dvh - 62px) !important;
}
.tour-drawer-inner {
    max-height: calc(100vh - 62px);
}
  .shared_item.dashboard_card,
  .Library.dashboard_card,
  .Dashboard_Counter {
    margin-left: 20px;
    margin-right: 20px;
  }

  .sideDrawer.active {
    transform: translateX(0px);
    z-index: 99;
  }

  .modal-footer .btn {
    min-width: calc(50% - 20px);
  }

  .login-form,
  .login-content {
    min-height: calc(100vh - 60px);
  }

  .change_password {
    margin: 0 auto;
  }

  a.navbar-brand img {
    margin-right: 60px;
  }

  .userInfo h5 {
    font-size: 20px;
    text-transform: capitalize;
    font-weight: 400;
  }

  .edit_profile label.form-label,
  .change_password label.form-label {
    font-size: 1.2rem;
  }

  .drawerBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #000;
    height: 100%;
    z-index: 98;
    opacity: 0;
    transform: translateX(-100%);
  }

  .sideDrawer.active+.drawerBackdrop {
    transform: translateX(0);
    opacity: 0.4;
  }

  .dzu-dropzone {
    height: 125px !important;
    text-align: center;
  }

  .modal-wrapper-thankyou {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35vh;
    text-align: center;
    width: 78%;
    margin-left: 10%;
    margin-right: 10px;
    margin-top: 50%;
  }

  .modal-dialog.drop-modal.modal-md {
    max-width: calc(100% - 40px);
  }
}

@media only screen and (max-width: 576px) {
  .modal-dialog.drop-modal {
    max-width: calc(100% - 30px);
  }

  .change_password .btn.btn-outline-dark,
  .edit_profile .btn.btn-outline-dark {
    float: right;
  }

  .edit_profile {
    max-width: 400px;
    margin: 0 auto;
  }

  .edit_profile .btn {
    min-width: calc(50% - 19px);
  }

  .imageModal li[role="button"] {
    width: 8px;
    height: 8px;
    min-width: 8px;
  }

  .inner_lists {
    max-height: calc(100vh - 202px);
    overflow-y: auto;
  }

  li.create-virtul-tour-nav-itme.nav-item {
    width: 100%;
    margin-top: 7px;
  }

  ul.create-virtul-tour-nav.nav.nav-tabs {
    flex-wrap: initial !important;
    flex-direction: column;
  }

  li.create-virtul-tour-nav-itme.nav-item a {
    border-radius: 6px !important;
    margin-right: 0;
  }
}

@media only screen and (max-width: 576px) and (min-width:480px) {
  .userInfo {
    max-width: 450px;
    margin: 0 auto;
  }

  .VR_rooms .col-xl-3,
  .userCard .col-lg-3 {
    max-width: 50%;
  }

  .assets_image {
    height: 300px;
  }

  .assets_item .card.selected .assets_image {
    height: calc(300px - 16px);
  }

  .pin-management .col-xl-3 {
    max-width: 50%;
  }
}

@media only screen and (max-width: 576px) {
  .add-input-boxes-container_lable_1 {
    width: 50% !important;
  }

  .cancel-input-boxes-container {
    /* height: 24px; */
    cursor: pointer;
    display: contents;
  }

  .myDiv {
    width: 300px;
    padding: 15px 15px 20px;
    top: 55px;
    left: -10px;
  }
  .myDiv:after {
      top: -6px;
      left: 20px;
  }
  span.tagButtonInfo {
      padding: 10px 15px;
      font-size: 12px;
      margin-bottom: 10px;
  }
  .addtag {
      bottom: 10px;
  }
  .cancel-input-boxes-container img {
    margin-top: 10px;
    height: 15px;
  }
.myDiv .btn {
    margin-top: 0px !important;
}
}

@media only screen and (max-width: 480px) {
  .assets_image {
    height: 200px;
  }
  .addtag span.tagButtonInfo{
    min-width: 300px;
    font-size: 12px;
    padding: 10px 10px;
    bottom: 50px;
  }

}